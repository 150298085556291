<template>
  <div>
    <b-modal id="modal-login" no-close-on-backdrop size="lg" centered ok-only hide-footer="true" title="News">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group ref="image" label="Image" invalid-feedback="Image is required.">
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner variant="primary" type="grow" class="m-5" label="Spinning"></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img rounded :src="plus" style="height: 150px; width: 150px; object-fit: cover" />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <small class="text-muted">Recommended pixels are 850 x 950</small>
          </b-col>
        </b-row>

        <b-form-group label="Title" invalid-feedback="Title is required.">
          <b-form-input
            ref="name"
            placeholder="Enter Title here."
            required
            @focusout="CheckName()"
            v-model="myObj.title"
          />
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="Author" invalid-feedback="Author is required.">
              <b-form-input
                ref="author"
                placeholder="Enter author name here."
                required
                @focusout="CheckAuthor()"
                v-model="myObj.author"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Date" invalid-feedback="Select valid date." ref="date">
              <flat-pickr
                v-model="myObj.date"
                class="form-control"
                placeholder="Select date."
                @on-change="checkDate()"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Content" ref="content" invalid-feedback="Content is required.">
          <VueTrix v-model="myObj.content" placeholder="Enter content" />
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="AddFlavour()" :disabled="request">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <!-- <b-form-group label="Search Business Type" invalid-feedback="Search field">
      <b-form-input
        id="name-input"
        ref="name"
        placeholder="Enter business name to search"
        required
        @input="SearchData()"
        v-model="searchQuery"
      />
    </b-form-group> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add News</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- <b-col md="2" cols="4">
            <label for="">Filter</label>
            <v-select
              placeholder="Select status."
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              @input="FilterTypes()"
              :clearable="false"
            />
          </b-col> -->
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields">
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(image)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="image" />
          </template>

          <template #cell(date)="data">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item.id)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                size="16"
                @click="DeleteFlavour(data.item.id)"
              >
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

import VueTrix from "vue-trix";

export default {
  components: {
    //Multiselect,
    VueTrix,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      fileProfile: "",
      logoloading: false,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fields: [
        "#",
        { label: "image", key: "image" },
        { label: "Title", key: "title" },
        { label: "author", key: "author" },
        { label: "date", key: "date" },
        "actions",
      ],
      filterStatus: "",
      items: [],
      request: false,
      myObj: {
        id: 0,
        title: "",
        content: "",
        date: "",
        author: "",
        image: "",
      },
      searchQuery: "",
    };
  },
  methods: {
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },
    FilterTypes() {
      var axios = require("axios");

      if (this.filterStatus == "All") {
        var config = {
          method: "get",
          url: "https://waqta.appick.io/api/business",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        var config = {
          method: "get",
          url: "https://waqta.appick.io/api/business/LoadData?status=" + this.filterStatus,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      // this.$store.state.userData.userID
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAuthor() {
      var elem = this.$refs["author"];
      if (this.myObj.author.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkContent() {
      var elem = this.$refs["content"];
      if (this.myObj.content.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDate() {
      var elem = this.$refs["date"];
      if (this.myObj.date == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      var elem = this.$refs["status"];
      if (this.myObj.status == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal(id) {
      this.$router.push({
        name: "single-news",
        params: { id: id },
      });
      // if (id != 0) {
      //   var axios = require("axios");
      //   var config = {
      //     method: "get",
      //     url: "https://aafia.cloudious.net/api/News/" + id,
      //     headers: {
      //       Authorization: "bearer " + this.$store.state.userData.token,
      //     },
      //   };

      //   axios(config)
      //     .then((response) => {
      //       // console.log(response);
      //       this.myObj = response.data.data;
      //       if (this.myObj.image) {
      //         this.logoloading = "loaded";
      //         this.plus = this.myObj.image;
      //       } else {
      //         this.logoloading = false;
      //         this.plus = "https://cdn.cloudious.net/file-1678189226453-406060527.png";
      //       }
      //       this.$bvModal.show("modal-login");
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      // } else {
      //   this.myObj = {
      //     id: 0,
      //     title: "",
      //     content: "",
      //     date: null,
      //     author: "",
      //     image: "",
      //   };
      //   this.logoloading = false;
      //   this.plus = "https://cdn.cloudious.net/file-1678189226453-406060527.png";
      //   this.$bvModal.show("modal-login");
      // }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://aafia.cloudious.net/api/News/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire("Success!", "News has been deleted.", "success").then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.plus = e.data.myresp[0].path;

            this.myObj.image = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    AddFlavour() {
      // this.CheckImage();
      this.CheckName();
      this.CheckAuthor();
      this.checkDate();
      this.checkContent();
      if (
        // this.CheckImage() == false ||
        this.CheckName() == false ||
        this.checkDate() == false ||
        this.CheckAuthor() == false ||
        this.checkContent() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        if (this.myObj.id == 0) {
          console.log("Obj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://aafia.cloudious.net/api/News",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                console.log(response.data);
                Swal.fire("Success!", "News added.", "success").then((res) => {
                  this.$bvModal.hide("modal-login");
                  this.request = false;
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        } else {
          //Edit
          console.log("edit_Obj", this.myObj);
          var axios = require("axios");
          // this.$store.state.userData.userID
          var config = {
            method: "put",
            url: "https://aafia.cloudious.net/api/News/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.$bvModal.hide("modal-login");
                this.request = false;
                this.LoadData();
                this.$bvToast.toast("News updated.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        }
      }
    },
    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/News",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
